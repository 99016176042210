import React from 'react';
import { tv } from 'tailwind-variants';
import useLockBodyScroll from '../../hooks/useLockBodyScroll';
import CancelIcon from '../../../assets/svg/redesign/cancel.svg';
var dialogVariants = tv({
    slots: {
        backdrop: 'absolute inset-0 bg-black/50 z-10 flex flex-col-reverse md:flex-row md:items-center md:justify-center',
        container: 'bg-final-look md:w-[592px] w-full rounded-t-xl md:rounded-xl overflow-hidden',
        header: 'relative flex justify-center items-center text-center bg-white p-1 h-6',
        close: 'absolute right-2 top-0 size-4 cursor-pointer p-2',
        content: 'p-4',
    },
});
export var Dialog = function (_a) {
    var title = _a.title, children = _a.children, onClose = _a.onClose, className = _a.className;
    var classes = dialogVariants();
    useLockBodyScroll();
    return (React.createElement("div", { className: classes.backdrop(), onClick: function () { return onClose === null || onClose === void 0 ? void 0 : onClose(); } },
        React.createElement("div", { className: classes.container(), onClick: function (e) { return e.stopPropagation(); } },
            React.createElement("div", { className: classes.header() },
                React.createElement("span", null, title),
                React.createElement(CancelIcon, { className: classes.close(), onClick: function () { return onClose === null || onClose === void 0 ? void 0 : onClose(); } })),
            React.createElement("div", { className: classes.content({ className: className }) }, typeof children === 'function' ? children(function () { return onClose === null || onClose === void 0 ? void 0 : onClose(); }) : children))));
};
