// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';

import { Textarea } from '../redesign/Textarea';

class FormTextarea extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    // Number of characters user can enter
    maxLength: PropTypes.number,
    // If trueish we show the number of chars left to enter
    className: PropTypes.string,
    showRemaining: PropTypes.bool,
    mandatory: PropTypes.bool,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
  };

  static contextTypes = {
    registerFormElement: PropTypes.func,
    validateField: PropTypes.func,
  };

  state = {};

  componentDidMount() {
    this.context.registerFormElement && this.context.registerFormElement(this.props.name, this);
  }

  get value() {
    return this._ref.value;
  }

  validate() {
    const { validateField } = this.context;

    if (validateField) {
      const localError = validateField(this.props.name, this.value);
      this.setState({
        localError,
      });
    }
  }

  clearLocalError() {
    this.setState({
      localError: false,
    });
  }

  @autobind
  onBlur() {
    const { onBlur } = this.props;
    this.validate();
    if (onBlur) onBlur(this.value, this);
  }

  @autobind
  onChange() {
    const { onChange } = this.props;
    if (onChange) onChange(this.value);
  }

  get hasChanged() {
    return this.value !== (this.props.value || '');
  }

  render() {
    const { name, label, mandatory, disabled, maxLength, hasError, onFocus } = this.props;
    const { localError } = this.state;
    const error = hasError || localError;

    return (
      <Textarea
        id={name}
        label={label}
        mandatory={mandatory}
        ref={(ref) => (this._ref = ref)}
        value={this.state.value}
        disabled={disabled}
        maxLength={maxLength}
        onFocus={onFocus}
        onChange={this.onChange}
        onBlur={this.onBlur}
        error={error}
      />
    );
  }
}

export default FormTextarea;
