// TODO: add flow types

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { autobind } from 'core-decorators';
import { Breadcrumb } from '../redesign/Breadcrumb';

import { bookingRequestShape } from '../../utils/PropTypes';
import tracking from '../../tracking';

@inject('authStore')
export default class BreadcrumbLink extends Component {
  static propTypes = {
    path: PropTypes.string,
    text: PropTypes.string,
    bookingRequest: bookingRequestShape,
  };

  static contextTypes = {
    breadcrumbsInfo: PropTypes.array,
    setBreadcrumbTitle: PropTypes.func,
  };

  // TUICUNIT-3782
  @autobind onLogoutClick() {
    this.props.authStore.logout().then(() => {
      // We make a hard reset here, to be sure, we don't have any states saved in some stores
      // or anywhere else
      window.location.href = this.props.authStore.user.backLink ? this.props.authStore.user.backLink : '/login';
    });
  }

  render() {
    const { path, text, bookingRequest } = this.props;
    const { setBreadcrumbTitle } = this.context;
    if (setBreadcrumbTitle) setBreadcrumbTitle(path, text, bookingRequest);

    const previousItem = this.context.breadcrumbsInfo.slice(-2)[0];

    // TUICUNIT-3782
    const shouldDoDashboardRedirect =
      previousItem.text === 'Meine Reise' && this.props.authStore.user && this.props.authStore.user.backLink;

    return (
      <Breadcrumb
        backAnchor={{
          href: previousItem.path,
          onClick: () => {
            tracking.click('breadcrumb.link.' + previousItem.text);
            if (shouldDoDashboardRedirect) {
              this.onLogoutClick();
            }
          },
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </Breadcrumb>
    );
  }
}
