// @flow
import * as React from 'react';

import { Tile } from '../../../components/tiles/NewTile';
import PdfTile from '../../../components/tiles/PdfTile';

import type BoardingPass from '../../../models/BoardingPass';
import type PartyMember from '../../../models/PartyMember';

import SmartphoneIcon from '../../../../assets/svg/redesign/smartphone.svg';
import AddWalletAppleIcon from '../../../../assets/svg/redesign/add-wallet-apple.svg';
import AddWalletGoogleIcon from '../../../../assets/svg/redesign/add-wallet-google.svg';

type Props = {
  boardingPass: ?BoardingPass,
  user: Object,
  partyMember: PartyMember,
  travelParty: PartyMember[],
  onClick: (partyMember: PartyMember, url: string, noPinNeeded: boolean) => void,
  disabled: boolean,
};

const CheckedInMember = ({ boardingPass, user, partyMember, onClick, disabled }: Props) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <PdfTile
        title="Schiffspass drucken"
        link={!disabled && boardingPass ? boardingPass.pdfLinkPrint : ''}
        print
        icon="printer"
        user={user}
        disabled={disabled}
        onClick={
          !disabled
            ? (e) => {
                e && e.preventDefault();
                onClick && onClick(partyMember, 'pdfLinkPrint', true);
              }
            : null
        }
      />
      <PdfTile
        title="Schiffspass als PDF speichern"
        noPadding
        link={!disabled && boardingPass ? boardingPass.pdfLink : ''}
        icon="download-document"
        user={user}
        disabled={disabled}
        onClick={
          !disabled
            ? (e) => {
                e && e.preventDefault();
                onClick && onClick(partyMember, 'pdfLink', true);
              }
            : null
        }
      />
      <Tile
        title="Zu Wallet hinzufügen"
        link={!disabled && boardingPass ? boardingPass.walletLink : ''}
        linkIsExternal
        icon={SmartphoneIcon}
        disabled={disabled}
        rawBadge
        badge={<AddWalletAppleIcon className="h-10" />}
        onClick={
          !disabled
            ? (e) => {
                e && e.preventDefault();
                onClick && onClick(partyMember, 'walletLink', true);
              }
            : null
        }
      />
      <div style={{ display: 'none' }}>
        <Tile
          title="Zu Wallet hinzufügen"
          link={!disabled && boardingPass ? boardingPass.walletLink : ''}
          linkIsExternal
          icon={SmartphoneIcon}
          rawBadge
          badge={<AddWalletGoogleIcon className="h-10" />}
          disabled={disabled}
          onClick={
            !disabled
              ? (e) => {
                  e && e.preventDefault();
                  onClick && onClick(partyMember, 'walletLinkGoogle', true);
                }
              : null
          }
        />
      </div>
    </div>
  );
};

export default CheckedInMember;
