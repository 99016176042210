// @flow
import * as React from 'react';

const ManifestPaymentCashOption = () => (
  <div>
    <p>
      Barzahlungen nehmen Sie direkt an Bord vor.
      <br />
      Bitte vergewissern Sie sich am Tag der Abreise, dass Sie entsprechend genug Bargeld mit an Bord nehmen.
    </p>
  </div>
);

export default ManifestPaymentCashOption;
