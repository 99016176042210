// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { routerShape } from 'react-router/lib/PropTypes';

import LoadingIndicator from '../../LoadingIndicator';
import Headline from '../../Headline';
import preload from '../decorators/preload';
import OverviewPageHeader from '../../CheckIn/Overview/PageHeader';
import CheckedInMember from '../../CheckIn/Overview/CheckedInMember';
import NotCheckedInMember from '../../CheckIn/Overview/NotCheckedInMember';
import storage from '../../../utils/storage';

import { MASTERDATA_STATUS_CHECKIN_TOO_CLOSE_TO_EMBARKATION } from '../../../config/constants';

import { autobind } from 'core-decorators';
import pinStorage from '../../../utils/pinStorage';
import { PinDialog } from '../../pin/PinDialog';

import { setViewed, getViewed } from '../../Home/ForceCheckIn';
import { setViewedDigitalHealth } from '../../Home/ForceDigitalHealth';

import ContentBox from '../../ContentBox';
import InfoIcon from '../../../../assets/svg/inline/ic-info.inline.svg';

import type BoardingStore from '../../../stores/BoardingStore';
import type MasterStore from '../../../stores/MasterStore';
import type PartyMember from '../../../models/PartyMember';
import type TilesStore from '../../../stores/TilesStore';

import { getCheckinStatusText, isCheckedIn, isDisabled, userMustEnterPin, openBoardingPassLink } from './utils';

type Props = {
  boardingStore: BoardingStore,
  masterStore: MasterStore,
  tilesStore: TilesStore,
};

type State = {
  showPinModal: boolean,
  loading: boolean,
  partyMember: ?PartyMember,
  url: ?string,
  error: ?Object,
};

@inject('boardingStore', 'tilesStore')
@preload({ masterStore: 'MasterStore' })
@observer
export default class PageCheckInOverview extends Component<Props, State> {
  static breadcrumb = 'Online Check-in Übersicht';
  static contextTypes = {
    router: routerShape,
  };

  winName = 'pdf-link';
  windowObject: ?WindowProxy;

  state = {
    loading: false,
    showPinModal: false,
    partyMember: null,
    url: null,
    error: null,
  };

  componentDidMount() {
    this.checkPinState();
    storage.remove('language');
    this.props.tilesStore.fetchAll();
  }

  // TUICUNIT-4673 - pin state wasn't checked after entry
  checkPinState() {
    // TUICUNIT-911
    let error = pinStorage.getApiPinError();
    if (error) {
      const { masterStore } = this.props;
      const partyMember = masterStore.getTravelPartyMemberByMpi(error.mpi);

      if (partyMember) {
        this.setState({
          partyMember,
          showPinModal: true,
          error,
          url: pinStorage.getUrl(partyMember.mpi),
        });
      }

      pinStorage.removeApiPinError();
    }
  }

  @autobind // TUICUNIT-911
  onPinEnter(pin: string) {
    const { partyMember, url } = this.state;
    if (!partyMember) return;
    const { mpi } = partyMember;

    // checkin/health not open in a new tap
    if (url.indexOf('checkin/health') === -1) {
      // open-link process after entering the PIN
      // open new window before ajax call to bypass pop-up blocker
      this.windowObject = window.open('about:blank', this.winName); // TUICUNIT-919
    }

    pinStorage.setPin(mpi, pin);
    pinStorage.setUrl(mpi, url);

    pinStorage.setActiveProcess({ mpi });

    // seems to be legacy that's not there anymore
    // if (url.indexOf('checkin/health') === -1) {
    //   this.fetchPasses();
    // }

    // checkin/health not open in a new tap
    if (url.indexOf('checkin/health') !== -1) {
      this.context.router.replace(url);
      return;
    }
  }

  @autobind // TUICUNIT-911
  onTileClick(partyMember: PartyMember, url: string, boardingPass: boolean) {
    const { boardingStore } = this.props;
    const pin = partyMember ? pinStorage.getPin(partyMember.mpi) : null;

    // TUICUNIT-2248: pin shit dirt
    // TUICUNIT-2614: no more PIN for ShipPasses
    if (boardingPass) {
      boardingStore.passesForMpi(partyMember.mpi).then(() => {
        const boardingPassStore = (boardingStore.passes || []).find((pass) => pass.mpi === partyMember.mpi);
        openBoardingPassLink(boardingPassStore, url, this.winName).then(() => {
          if (url === 'walletLink') {
            // TUICUNIT-919
            window.setTimeout(() => {
              this.windowObject && this.windowObject.close();
            }, 1000);
          }
        });
      });

      return;
    }
    if (pin || !partyMember.pinAvailable) {
      // not checked in user
      this.context.router.replace(url);
      return;
    }

    this.setState({
      showPinModal: true,
      partyMember,
      url,
    });
  }

  // TUICUNIT-2919
  // TUICUNIT-3474: deprecated
  renderHealthHint() {
    const masterData = this.props.masterStore.masterData;
    //(TUICMRL-783) PartialSave ist only allow by more then 7 days
    const under7Days = !masterData.manifestAllowsPartialSave;
    const journeyOngoing = masterData.journeyOngoing;

    if (masterData.digitalHealthStateNotAvailable && under7Days && !journeyOngoing) {
      return (
        <ContentBox title="Technische Störung" titleIcon={<InfoIcon />}>
          <p>
            Wir bitten um Verständnis, dass der Gesundheitsfragebogen derzeit infolge einer technischen Herausforderung
            zeitweise nicht dargestellt werden kann. Wir bitten Sie in diesem Fall, es zu einem späteren Zeitpunkt
            nochmals zu versuchen.
          </p>
        </ContentBox>
      );
    }
    return null;
  }

  render() {
    const { boardingStore, masterStore, tilesStore } = this.props;
    const { travelParty, checkin } = masterStore.masterData;
    const { partyMember, url, showPinModal, error, loading } = this.state;

    if (getViewed() === false) {
      setViewedDigitalHealth();
    }
    // TUICUNIT-1994: forceCheckin: set local storage (force-checkin: false)
    setViewed();
    if (loading || tilesStore.isLoading) {
      return <LoadingIndicator />;
    }
    const { tiles } = tilesStore;

    const membersWithAvailableCheckin = travelParty.filter((partyMember) => partyMember.checkinAvailable);

    const allCheckedIn = travelParty.every((partyMember) => partyMember.checkinCompleted);

    const allCheckinAble =
      !!membersWithAvailableCheckin.length &&
      membersWithAvailableCheckin.every((partyMember) => partyMember.checkinEnabled);

    const checkInBlocked = checkin.reason === MASTERDATA_STATUS_CHECKIN_TOO_CLOSE_TO_EMBARKATION;

    return (
      <div className="flex flex-col">
        <Headline title="Online Check-in" />
        <OverviewPageHeader
          allCheckedIn={allCheckedIn}
          allCheckinAble={allCheckinAble}
          checkInBlocked={checkInBlocked}
          faqTile={tiles.faq}
        />
        {/*TUICUNIT-3474: this.renderHealthHint() */}
        <section className="space-y-10 mt-10">
          {travelParty.map((partyMember: PartyMember) => (
            <div key={partyMember.mpi}>
              <h2>
                {partyMember.displayName}
                {partyMember.checkinCompleted ? ' ✓' : ''}
              </h2>
              <p>{getCheckinStatusText(partyMember.checkinStatusReason)}</p>
              {isCheckedIn(partyMember) ? (
                <CheckedInMember
                  boardingPass={(boardingStore.passes || []).find((pass) => pass.mpi === partyMember.mpi)}
                  mustEnterPin={userMustEnterPin(partyMember)}
                  disabled={isDisabled(partyMember)}
                  partyMember={partyMember}
                  travelParty={travelParty}
                  user={this.props.masterStore.user}
                  onClick={this.onTileClick}
                />
              ) : (
                <NotCheckedInMember
                  partyMember={partyMember}
                  travelParty={travelParty}
                  onClick={this.onTileClick}
                  tiles={tiles}
                />
              )}
            </div>
          ))}
        </section>

        {showPinModal && partyMember && (
          <PinDialog
            title="Informationen"
            url={url}
            error={error}
            partyMember={partyMember}
            onSubmit={isCheckedIn(partyMember) ? this.onPinEnter : null}
            onClose={() => {
              pinStorage.removePin(partyMember.mpi);
              pinStorage.removeActiveProcess();
              this.setState({
                partyMember: null,
                showPinModal: false,
                error: null,
              });
            }}
          />
        )}
      </div>
    );
  }
}
