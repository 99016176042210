// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { routerShape } from 'react-router/lib/PropTypes';
import { autobind } from 'core-decorators';

import type AuthStore from '../stores/AuthenticationStore';
import LoadingIndicator from './LoadingIndicator';
import FormInput from './form/Input';
import FormButton from './form/Button';

import validators, { validateWith } from '../utils/forms/validators';
import validation from '../utils/forms/validation';
import storage from '../utils/storage';
import LockoutMessage, { TYPE_ANY } from './Lockout/Message';
import FormCheckbox from './form/Checkbox';
import { adjustedDate } from '../utils/date';

const FORM_LOGIN_VALIDATORS = {
  firstName: [validateWith(validators.isRequired, 'Bitte geben Sie Ihren Vornamen an.')],
  lastName: [validateWith(validators.isRequired, 'Bitte geben Sie Ihren Nachnamen an.')],
  processNumber: [
    validateWith(validators.isRequired, 'Bitte geben Sie Ihre Vorgangsnummer an (6-7 Ziffern).'),
    validateWith(validators.isNumeric, 'Bitte geben Sie Ihre Vorgangsnummer an (6-7 Ziffern).'),
    validateWith(validators.isLength, { min: 6, max: 7 }, 'Ihre Vorgangsnummer sollte 6-7 Ziffern lang sein.'),
  ],
};

const FORM_LOGIN_LABELS = {
  firstName: 'Vorname',
  lastName: 'Nachname',
  processNumber: 'Vorgangsnummer',
};

const FORM_LOGIN_MAX_LENGTH = {
  firstName: 1000,
  lastName: 1000,
  processNumber: 7,
};

const DEFAULT_BACKEND_ERROR_MSG =
  'Bitte überprüfen Sie Ihre Eingaben und geben Sie Ihren Vor- und Nachnamen wie in der Buchungsbestätigung angegeben ein. Achten Sie auf Groß- und Kleinschreibung sowie die Schreibweise von möglichen Umlauten (ä, ö, bzw. ae, oe, etc.).';

const now = () => Math.round(adjustedDate(new Date()).getTime() / 1000);

type Props = {
  authStore: AuthStore,
  className: ?string,
  showInitialError: ?boolean,
};

type State = {
  initialError: boolean,
  isLoading: boolean,
  showErrors: boolean,
  backendError: boolean,
  hasSubmitted: boolean,
  focusedField: ?string,
  lockoutError: boolean,
  lockoutErrorReload: boolean,
  currentTime: number,
  stayLoggedIn: boolean,
  disableFormElements: boolean,
  form: Object,
  errors: ?Object,
};

@inject('authStore')
@observer
export default class LoginForm extends Component<Props, State> {
  static contextTypes = {
    router: routerShape,
  };

  constructor(props: Props) {
    super(props);

    const lockedOut = !!this.getLockoutData();
    if (lockedOut) {
      this.updateLockoutState();
    }

    this.state = {
      initialError: !!props.showInitialError,
      isLoading: false,
      showErrors: !!props.showInitialError,
      backendError: false,
      hasSubmitted: false,
      focusedField: null,
      lockoutError: lockedOut,
      lockoutErrorReload: lockedOut,
      currentTime: now(),
      stayLoggedIn: false,
      disableFormElements: this.isAnyCredentialsLockout(),
      form: {
        firstName: {},
        lastName: {},
        processNumber: {},
      },
      errors: null,
    };
  }

  hasInvalidField(formObject: Object): boolean {
    return Object.values(formObject).some((item) => item.error);
  }

  doLoginRequest() {
    this.setState({
      isLoading: true,
      initialError: false,
    });

    this.props.authStore
      .login(
        this.state.form.firstName.value,
        this.state.form.lastName.value,
        this.state.form.processNumber.value,
        this.state.stayLoggedIn
      )
      .then(
        () => {
          storage.remove('login-locked');
          this.context.router.replace(storage.get('login-redirect') || '/');
        },
        (response) => {
          if (response.status === 423 || response.lockoutType) {
            storage.set('login-locked', response, response.lockoutStillSeconds);
            this.updateLockoutState();
          }

          // show the default error message and highlight all fields with error state
          this.setState({
            showErrors: true,
            isLoading: false,
            hasSubmitted: true,
            backendError: true,
            lockoutError: !!response.lockoutType,
            lockoutErrorReload: false,
            currentTime: now(),
            disableFormElements: !!response.lockoutType,
            errors: {
              firstName: true,
              lastName: true,
              processNumber: true,
            },
          });
        }
      );
  }

  _getFormState(fieldName: string, fieldValue: any): Object {
    return {
      value: fieldValue,
      error: validation.validateInput(fieldValue, FORM_LOGIN_VALIDATORS[fieldName], fieldName),
    };
  }

  @autobind
  onSubmit(event: Object) {
    event.preventDefault();
    const formObject = Object.keys(this.state.form).reduce(
      (formState, fieldName) => ({
        ...formState,
        [fieldName]: this._getFormState(fieldName, this.state.form[fieldName].value || ''),
      }),
      {}
    );

    const isInvalid = this.hasInvalidField(formObject);

    this.setState({
      form: formObject,
      hasSubmitted: true,
      initialError: false,
      showErrors: isInvalid,
    });

    if (!isInvalid) {
      this.doLoginRequest();
    }
  }

  onFormInputBlur(fieldName: string, fieldValue: any) {
    this.setState({ focusedField: null });
    this.onFormInputChange(fieldName, fieldValue);
  }

  @autobind
  onFormInputChange(fieldName: string, fieldValue: any) {
    this.setState({
      form: {
        ...this.state.form,
        [fieldName]: this._getFormState(fieldName, fieldValue),
      },
      disableFormElements: false,
    });
  }

  @autobind
  onStayLoggedInChange(value: boolean) {
    this.setState({
      stayLoggedIn: value,
      disableFormElements: false,
    });
  }

  getErrorMessages() {
    if (!this.state.showErrors) {
      return [];
    }

    let Messages = Object.values(this.state.form).reduce((message, field) => {
      if (field.error) {
        message.push(field.error);
      }
      return message;
    }, []);

    if (this.state.backendError || this.state.initialError) {
      Messages.unshift(DEFAULT_BACKEND_ERROR_MSG);
    }

    return Messages;
  }

  getLockoutData(): Object | null {
    return storage.get('login-locked');
  }

  isAnyCredentialsLockout(): boolean {
    const stored = this.getLockoutData();
    return !!stored && stored.lockoutType === TYPE_ANY;
  }

  updateLockoutState() {
    if (this.getLockoutData()) {
      window.setTimeout(() => {
        this.setState({
          currentTime: now(),
        });
        this.updateLockoutState();
      }, 1000 * 5);
    } else {
      this.setState({
        lockoutError: false,
        lockoutErrorReload: false,
        disableFormElements: false,
      });
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        <div className="page-login-form-wrapper">
          <h2>Melden Sie sich an</h2>
          <p>
            Bitte melden Sie sich mit Ihrem Vor- und Nachnamen sowie Ihrer Vorgangsnummer an, die Sie Ihrer
            Buchungsbestätigung entnehmen können. Achten Sie auf Groß- und Kleinschreibung sowie die Schreibweise von
            möglichen Umlauten (ä, ö, bzw. ae, oe, etc.).
          </p>
          {!this.isAnyCredentialsLockout() && (
            <form className="page-login-form" onSubmit={this.onSubmit} noValidate>
              {Object.keys(this.state.form).map((fieldName) => (
                <FormInput
                  autoComplete="off"
                  key={fieldName}
                  name={fieldName}
                  label={FORM_LOGIN_LABELS[fieldName]}
                  hasError={
                    this.state.hasSubmitted &&
                    this.state.form[fieldName].error &&
                    (fieldName !== this.state.focusedField || this.state.showErrors)
                  }
                  onFocus={() => this.setState({ focusedField: fieldName })}
                  onBlur={(value) => this.onFormInputBlur(fieldName, value)}
                  onChange={(value) => this.onFormInputChange(fieldName, value)}
                  maxLength={FORM_LOGIN_MAX_LENGTH[fieldName]}
                />
              ))}
              <p className="annotation">
                Bitte geben Sie hier Ihre TUI Cruises Vorgangsnummer ohne Schrägstrich, führende Nullen und weitere
                Ziffern ein.
              </p>
              <FormCheckbox
                className="big"
                mandatory={false}
                disabled={this.state.disableFormElements}
                name="stayLoggedIn"
                label="Eingeloggt bleiben"
                checked={false}
                error={false}
                onChange={(value) => this.onStayLoggedInChange(value)}
              />
              {!this.state.isLoading ? (
                <div className="l-right">
                  <FormButton disabled={this.state.disableFormElements} dark>
                    Einloggen
                  </FormButton>
                </div>
              ) : (
                <LoadingIndicator />
              )}
            </form>
          )}
          {this.state.lockoutError ? (
            <LockoutMessage
              currentTime={this.state.currentTime}
              reloaded={this.state.lockoutErrorReload}
              data={this.getLockoutData()}
            />
          ) : (
            <div
              className={classNames({
                'is-hidden': !this.state.showErrors,
                'page-login-ul': true,
                error: true,
              })}
            >
              {this.getErrorMessages().length > 0 || this.state.backendError || this.state.initialError
                ? `Ihre Anmeldung ist fehlgeschlagen:`
                : null}
              <ul>
                {this.getErrorMessages().map((message) => (
                  <li key={message}>{message}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}
