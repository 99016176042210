// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import omit from 'lodash/omit';

import FormBlock from './Block';

import validation from '../../utils/forms/validation';

export default class Form extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onError: PropTypes.func,
    validationRules: PropTypes.object,
    hasErrors: PropTypes.bool,
    allowBlockwiseSubmit: PropTypes.bool,
  };

  static childContextTypes = {
    validateField: PropTypes.func,
  };

  state = {
    errors: {},
  };

  getChildContext() {
    return {
      validateField: this.validateField,
    };
  }

  @autobind
  onSubmit(event) {
    event.preventDefault();
    const values = this.props.allowBlockwiseSubmit ? this._block.changedValue : this._block.value;
    const errors = this.validate(values);
    const hasErrors = this.containsError(errors);
    if (hasErrors) {
      this._block.validate(this.props.allowBlockwiseSubmit ? Object.keys(errors) : null);
      if (this.props.onError) this.props.onError(values);
      this.setState({
        errors,
        hasErrors,
      });
      return;
    }
    this.clearErrors();
    this.props.onSubmit(values);
  }

  clearErrors() {
    this.setState({
      errors: {},
      hasErrors: false,
    });
    this._block.clearLocalError();
  }

  validate(values) {
    return validation.validateForm(values, this.props.validationRules, values);
  }

  @autobind
  validateField(id, value) {
    // Manifest: src/js/models/ManifestFormValidation.js
    const values = this._block.value;
    return this._validateField(typeof id === 'string' ? [id] : id, value, this.props.validationRules, values);
  }

  _validateField(id, value, rules = {}, values) {
    const subRules = rules[id[0]];
    return id.length > 1
      ? this._validateField(id.slice(1), value, subRules, values)
      : validation.validateInput(value, subRules, values);
  }

  containsError(errors) {
    return !!Object.keys(errors).find((key) =>
      typeof errors[key] === 'string' ? true : errors[key] && this.containsError(errors[key])
    );
  }

  renderGeneralError() {
    return this.state.hasErrors || this.props.hasErrors ? (
      <p className="error-message">Bitte überprüfen Sie die rot markierten Felder.</p>
    ) : null;
  }

  render() {
    return (
      <FormBlock
        ref={(ref) => {
          this._block = ref;
        }}
      >
        <form
          {...omit(this.props, ['validationRules', 'hasErrors', 'allowBlockwiseSubmit'])}
          onSubmit={this.onSubmit}
          ref={(ref) => {
            this._form = ref;
          }}
        >
          {this.renderGeneralError()}
          {this.props.children}
        </form>
      </FormBlock>
    );
  }
}
