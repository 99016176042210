import * as React from 'react';

import validators, { validateWith, validateWithAny } from '../../../utils/forms/validators';

import Headline from '../../Headline';

import type CheckInRequest from '../../../models/CheckInRequest';
import type PartyMember from '../../../models/PartyMember';
import type MasterData from '../../../models/MasterData';
import type { default as TimeSloteStore } from '../../../stores/TimeSloteStore';
import { Link } from '../../redesign/Link';
import Form from '../../form/Form';
import FormInput from '../../form/Input';
import MembersSelect from './MembersSelect';
import { Button } from '../../redesign/Button';

import CheckIcon from '../../../../assets/svg/redesign/check.svg';

// type NotReadyToCheckInType = {
//   reason: string,
//   reasonText: string,
//   travelParty: PartyMember[],
// };

type Props = {
  checkInRequest: CheckInRequest,
  membersReadyToCheckIn: PartyMember[],
  membersNotReadyToCheckIn: {
    reason: string,
    reasonText: string,
    travelParty: PartyMember[],
  }[],
  membersCompletedCheckIn: PartyMember[],
  onSubmit: () => any,
  masterData: MasterData,
  timeSloteStore: TimeSloteStore,
  isInAppView?: boolean,
};

const validationRules = {
  email: [
    validateWith(validators.isRequired, 'Bitte geben Sie Ihre E-Mail-Adresse an.'),
    validateWith(validators.isEmail, 'Bitte geben Sie Ihre E-Mail-Adresse an.'),
  ],
  mobile: [
    validateWithAny(
      [[validators.isLength, { min: 8 }], (x) => x === '0049'],
      'Bitte geben Sie eine gültige Mobilnummer an.'
    ),
    validateWith(
      validators.isCheckInPhone,
      {
        onlyDigits: true,
        international: true,
      },
      'Bitte geben Sie eine gültige Mobilnummer an.'
    ),
  ],

  members: [
    (members) => {
      if (!members.length) {
        return {
          ok: false,
          error: 'Bitte wählen Sie mind. eine Person aus, welche Sie einchecken möchten.',
        };
      }
      if (members.some((member) => member.dontCheck === true)) {
        return { ok: true };
      }
      /* TUICUNIT-3368: Check-in for Groups (@_@): need sometimes is this flag also for vip not set ¯\_:(_/¯ */
      if (members.some((member) => member.dontCheck === undefined)) {
        return { ok: true };
      }

      // TUICUNIT-1993
      const checkTimeSlote = members.every((m) => {
        if (m.timeSlote && m.timeSlote.state) {
          return true;
        } else if (m.arrivalAt) {
          return true;
        } else {
          return false;
        }
      });

      if (checkTimeSlote === false) {
        return {
          ok: false,
          error: 'Bitte wählen Sie ein Check-in Zeitraum aus.',
        };
      }

      if (members.some((member) => member.arrivalAt && (!member.arrivalAt.where || !member.arrivalAt.time))) {
        return {
          ok: false,
          error: 'Bitte geben Sie für jede ausgewählte Person den Ankunftsort und die Ankunftszeit an.',
        };
      }
      return { ok: true };
    },
  ],
};

const getArrivalAtNote = (member: PartyMember, checkInRequest: CheckInRequest, isGroupBooking: boolean): ?string => {
  const selectedMember = checkInRequest.requestData.selectedMembers.find((m) => m.mpi === member.mpi);

  if (!selectedMember) return null;

  /* TUICUNIT-3368: Check-in for Groups (@_@) */
  if (isGroupBooking) {
    return null;
  }

  if (member.hasTuicTravelToShip === true) {
    return { timeInfo1: 'Anreise findet mit TUI Cruises statt' };
  }

  if (selectedMember.timeSlote && selectedMember.timeSlote.state) {
    return {
      timeInfo1: `Check-in Zeitfenster: ${selectedMember.timeSlote.display}`,
    };
  }

  let fallbackDisplayTimeSlote = null;
  if (checkInRequest.backUpData) {
    fallbackDisplayTimeSlote = checkInRequest.backUpData.find((m) => m.mpi === member.mpi);
  }

  if (fallbackDisplayTimeSlote && fallbackDisplayTimeSlote.timeSlote) {
    return {
      timeInfo1: `Check-in Zeitfenster: ${fallbackDisplayTimeSlote.timeSlote.display}`,
    };
  }

  if (!selectedMember.arrivalAt || !selectedMember.arrivalAt.where || !selectedMember.arrivalAt.time) {
    return null;
  }
  const mapping = {
    airport: 'Flughafen',
    port: 'Hafen',
  };
  const where = mapping[selectedMember.arrivalAt.where];
  if (!where) return null;
  return {
    timeInfo1: `Ankunftsort: ${where}`,
    timeInfo2: `Ankunftszeit: ${selectedMember.arrivalAt.time} Uhr`,
  };
};

// const getPersonSelectDescription = (
//   boardingTime: ?string,
//   timeSlotsIsLoading: boolean,
//   hasTimeSlots: boolean
// ): string => {
//   const hint =
//     'Bitte wählen Sie aus, welche Teilnehmer Sie einchecken möchten und geben Sie für eine bessere Planbarkeit des Check-ins vor Ort die ungefähre Ankunftszeit aller Personen an.';
//   const boardingTextFallback =
//     'Tipp: Die frühestmögliche Zeit für den Check-in an Bord entnehmen Sie bitte Ihren Reiseunterlagen.';
//   const boardingText = 'Die frühestmögliche Zeit für den Check-in an Bord ist um [time] Uhr';

//   return `${hint}<br><br>${
//     timeSlotsIsLoading || hasTimeSlots
//       ? ''
//       : boardingTime
//       ? boardingText.replace('[time]', boardingTime)
//       : boardingTextFallback
//   }`;
// };

// const getCheckInLabel = (bookingList: TimeSloteBookingType, partyMember: PartyMember) => {
//   let result = null;

//   if (bookingList) {
//     const find = bookingList.find((item) => item.mpi === partyMember.mpi);
//     if (find) {
//       result = <p style={{ marginBottom: 12 }}>{find.label}</p>;
//     }
//   }

//   if (!result && partyMember.arrivalAtDisplay) {
//     result = <p style={{ marginBottom: 12 }}>{partyMember.arrivalAtDisplay}</p>;
//   }

//   return result;
// };

const CheckInProcessForm = ({
  checkInRequest,
  membersReadyToCheckIn,
  membersNotReadyToCheckIn,
  membersCompletedCheckIn,
  onSubmit,
  masterData,
  // timeSloteStore,
  isInAppView,
}: Props) => {
  /* TUICUNIT-3368: Check-in for Groups (@_@) */
  checkInRequest.setGroupBooking(masterData.isGroupBooking);

  return (
    <>
      <div>
        <Headline title="Online Check-in" />
        <p>
          Nutzen Sie die Vorteile des Online Check-Ins und sparen Sie sich die Wartezeit am Abfahrtstag. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Cur deinde Metrodori liberos commendas? Respondent extrema
          primis, media utrisque, omnia omnibus. Vide, quantum, inquam, fallare, Torquate. Vide igitur ne non debeas
          verbis nostris uti, sententiis tuis. Etenim si delectamur, cum scribimus, quis est tam invidus, qui ab eo nos
          abducat?
        </p>
        <p>
          <b>Benötigen Sie Hilfe?</b>
          <br />
          <Link href="https://www.meinschiff.com/faq/an-und-abreise?id=133" target={isInAppView ? '_self' : '_blank'}>
            Häufige Fragen zum Online Check-in
          </Link>
        </p>
      </div>
      <Form onSubmit={onSubmit} validationRules={validationRules}>
        <h2>Kontaktdaten</h2>
        <p>
          Teilen Sie uns Ihre Kontaktdaten mit, an die wir Ihnen nach erfolgreichem Online Check-In den Schiffspass,
          sowie weitere Informationen schicken sollen.
        </p>
        <div className="flex gap-4">
          <div className="w-full">
            <FormInput
              mandatory
              name="email"
              label="E-Mail"
              onChange={checkInRequest.changeEmail}
              value={checkInRequest.formData.email}
            />
            <p className="text-medium-emphasis text-xs">
              Bitte geben Sie die E-Mail-Adresse an, an die wir Ihnen nach dem Online Check-In den Schiffspass zusenden
              sollen.
            </p>
          </div>
          <div className="w-full">
            <FormInput
              trim
              name="mobile"
              label="Mobilnummer"
              onChange={checkInRequest.changeMobile}
              value={checkInRequest.formData.mobile}
              placeholder="0049"
            />
            <p className="text-medium-emphasis text-xs">
              Falls Sie am Tag der Anreise kostenlos per SMS über die Verfügbarkeit Ihrer Kabine informiert werden
              wollen, geben Sie hier bitte optional Ihre Mobilfunknummer an.
            </p>
          </div>
        </div>
        <h2 className="font-bold mt-8">Personenauswahl</h2>
        <p>
          Wählen Sie im folgenden Schritt alle Personen aus, die Sie vor Anreise online einchecken wollen. Bitte
          versichern Sie sich, dass für alle gewünschten Personen das Schiffsmanifest vollständig ausgefüllt wurde.
        </p>

        <div className="flex flex-col gap-4 mt-8">
          {membersReadyToCheckIn.length !== 0 && (
            <div>
              <h3 className="font-bold">Noch nicht eingecheckt:</h3>
              <MembersSelect
                masterData={masterData}
                name="members"
                members={membersReadyToCheckIn}
                selectedMembers={checkInRequest.formData.selectedMembers.slice()}
                onChange={checkInRequest.selectMembers}
              />
              <hr className="my-8" />
            </div>
          )}

          {membersCompletedCheckIn.length !== 0 && (
            <div>
              <h3 className="font-bold">Bereits eingecheckt:</h3>
              {membersCompletedCheckIn.map((partyMember) => {
                const arrivalAtNote = getArrivalAtNote(partyMember, checkInRequest, true /* groupBooking.status */);

                return (
                  <div key={partyMember.mpi} className="flex gap-2">
                    <CheckIcon className="size-5" />
                    <div className="flex flex-col">
                      <span className="font-bold">{partyMember.displayName}</span>
                      <span>
                        {arrivalAtNote?.timeInfo1 && <span>{arrivalAtNote.timeInfo1}</span>}
                        {arrivalAtNote?.timeInfo2 && <span>{arrivalAtNote.timeInfo2}</span>}
                      </span>
                    </div>
                  </div>
                );
              })}
              <hr className="my-8" />
            </div>
          )}

          {membersNotReadyToCheckIn.map(({ reasonText, travelParty }, index) => (
            <div key={index}>
              <h3 className="font-bold">{reasonText}</h3>
              <ul>
                {travelParty.map((partyMember) => (
                  <li key={partyMember.mpi}>
                    <span className="text-xl mx-3">•</span>
                    <span className="font-bold">{partyMember.displayName}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <p className="my-8">*Pflichtfelder</p>
        <Button type="submit">Jetzt verbindlich einchecken</Button>
      </Form>
    </>
  );

  // return (
  //   <div>
  //     <Headline title="Online Check-in" />
  //     <div className="l-row" style={{ marginBottom: 60 }}>
  //       <div className="l-col double l-mod-sub">
  //         <p className="l-mod">
  //           Damit Ihr Wohlfühlurlaub noch entspannter beginnt, checken Sie hier bereits vor der Reise ein.
  //         </p>
  //         <FAQTeaser enabled />
  //       </div>
  //       <div className="l-col right">
  //         <ContentBox title="Vorteile des Online Check-ins">
  //           <BenefitList
  //             benefits={[
  //               'Bis 0 Uhr am Tag Ihres Kreuzfahrtbeginns möglich',
  //               'Bequem von zu Hause',
  //               'Individuelle Benachrichtigung bei Freigabe Ihrer Kabine',
  //               "Schiffspass zum selbst ausdrucken oder bequem auf's Smartphone",
  //             ]}
  //           />
  //         </ContentBox>
  //       </div>
  //     </div>
  //     <Form onSubmit={onSubmit} validationRules={validationRules}>
  //       <LabeledInputBlock
  //         icon="check-in"
  //         title="Kontaktdaten"
  //         description="Teilen Sie uns Ihre Kontaktdaten mit, an die wir Ihnen nach erfolgreichem Online Check-in den Schiffspass sowie weitere Informationen schicken sollen."
  //       >
  //         <LabeledInputBlock.Column>
  //           <FormInput
  //             mandatory
  //             name="email"
  //             label="E-Mail"
  //             onChange={checkInRequest.changeEmail}
  //             value={checkInRequest.formData.email}
  //           />
  //           <p className="annotation">
  //             Bitte geben Sie Ihre E-Mail-Adresse an, an die wir Ihnen nach dem Online Check-in den Schiffspass zusenden
  //             sollen.
  //           </p>
  //         </LabeledInputBlock.Column>
  //         <LabeledInputBlock.Column>
  //           <FormInput
  //             trim
  //             name="mobile"
  //             label="Mobilnummer"
  //             onChange={checkInRequest.changeMobile}
  //             value={checkInRequest.formData.mobile}
  //             placeholder="0049"
  //           />
  //           <p className="annotation">
  //             Bitte geben Sie Ihre Mobilnummer im Format 0049170123456 ohne Leer- und Sonderzeichen an.
  //           </p>
  //           <p className="annotation">
  //             Falls Sie am Tag der Anreise kostenlos per SMS über die Verfügbarkeit Ihrer Kabine informiert werden
  //             wollen, geben Sie hier optional Ihre Mobilfunknummer an.
  //           </p>
  //         </LabeledInputBlock.Column>
  //       </LabeledInputBlock>
  //       <hr />
  //       <LabeledInputBlock
  //         icon="people"
  //         title="Personenauswahl"
  //         description={getPersonSelectDescription(
  //           masterData.earliestBoardingTime,
  //           timeSloteStore.isLoading,
  //           timeSloteStore.hasTimeSlots
  //         )}
  //       >
  //         <LabeledInputBlock.Column>
  //           <MembersSelect
  //             masterData={masterData}
  //             name="members"
  //             members={membersReadyToCheckIn}
  //             selectedMembers={checkInRequest.formData.selectedMembers.slice()}
  //             onChange={checkInRequest.selectMembers}
  //           />
  //         </LabeledInputBlock.Column>
  //         <LabeledInputBlock.Column>
  //           {membersNotReadyToCheckIn.map((notReady: NotReadyToCheckInType, index: number) => (
  //             <div key={index}>
  //               <p>{notReady.reasonText}</p>
  //               {notReady.travelParty.map((partyMember) => (
  //                 <div key={partyMember.mpi}>
  //                   <CheckboxGroupItem value={partyMember.mpi} disabled>
  //                     {partyMember.displayName}
  //                   </CheckboxGroupItem>
  //                   {notReady.reason === MASTERDATA_STATUS_CHECKIN_ALREADY_CHECKED_IN &&
  //                   !masterData.isGroupBookingBlocked ? (
  //                     <span>{getCheckInLabel(timeSloteStore.getBookingList, partyMember)}</span>
  //                   ) : null}
  //                 </div>
  //               ))}
  //             </div>
  //           ))}
  //         </LabeledInputBlock.Column>
  //       </LabeledInputBlock>
  //       <div className="l-right">
  //         <Button big dark type="submit">
  //           Daten überprüfen
  //         </Button>
  //       </div>
  //       <p className="annotation">*Pflichtfelder</p>
  //     </Form>
  //   </div>
  // );
};

export default CheckInProcessForm;
