// @flow

import React, { Component, type Node } from 'react';
import { inject, observer } from 'mobx-react';

import { DefaultLayout as RedesignDefaultLayout } from './components/redesign/Layout/DefaultLayout';
import Header from './components/Header';
import LoadingIndicator from './components/LoadingIndicator';
import isInAppView from './utils/isInAppView';
import type AuthStore from './stores/AuthenticationStore';
import { LayoutFooter } from './components/redesign/Layout/Footer';

type Props = {
  children: Node,
  params: {},
  authStore: AuthStore,
};

@inject('authStore')
@observer
class DefaultLayout extends Component<Props> {
  componentDidMount() {
    // const { authStore } = this.props;
    // if (authStore.isAuthenticated) {
    //   // TUICROLLOUTS-627 dismantling for 1.10.0.
    //   //    Restore for 1.11.0 if 1.10.0 was released
    //   authStore.getIdentity().catch(error => {
    //     console.log(error);
    //   });
    // }
  }

  render() {
    const { loading } = this.props.authStore;
    if (loading) return <LoadingIndicator />;

    return (
      <RedesignDefaultLayout
        header={<Header params={this.props.params} isInAppView={isInAppView} />}
        footer={isInAppView ? undefined : <LayoutFooter />}
      >
        {this.props.children}
      </RedesignDefaultLayout>
    );
  }
}

export default DefaultLayout;
