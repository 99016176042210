// @flow
import * as React from 'react';

import { Tile } from './NewTile';

import CheckinIcon from '../../../assets/svg/redesign/internet.svg';

import type PartyMember from '../../models/PartyMember';

type Props = {
  travelParty: PartyMember[],
  partyMember: PartyMember,
  configEnabled: boolean,
  configNotification: string,
};

const CheckinTile = ({ travelParty, partyMember, configEnabled, configNotification }: Props) => {
  const index = travelParty.indexOf(partyMember);
  if (index === -1 || !partyMember.paymentAvailable) return null;
  let badge = partyMember.checkinEnabled ? 'Jetzt einchecken' : partyMember.checkinAvailable ? null : 'Nicht verfügbar';
  let disabled = !partyMember.checkinEnabled;

  // TUICUNIT-2856 if tilesconfig enable ignore other state and badge
  if (configEnabled === false) {
    disabled = true;
    badge = configNotification;
  }

  return <Tile icon={CheckinIcon} title="Check-in" badge={badge} link={`/checkin/checkin`} disabled={disabled} />;
};

export default CheckinTile;
