// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button as RedesignButton } from './redesign/Button';

const Button = ({ children, disabled, fullWidth, white, dark, big, className, ...props }) => (
  <RedesignButton
    {...props}
    disabled={disabled}
    size={big ? 'lg' : 'md'}
    variant={white ? 'secondary' : dark ? 'dark' : 'primary'}
    className={classNames(className, { 'w-full': fullWidth })}
  >
    {children}
  </RedesignButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  white: PropTypes.bool,
  dark: PropTypes.bool,
  big: PropTypes.bool,
};

export default Button;
