// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';

import { optionsShape } from '../../utils/PropTypes';

import { Select } from '../redesign/Select';

class FormSelect extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.any,
    // If we want to show an empty option, use the placeholder
    placeholder: PropTypes.string,
    options: optionsShape.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    mandatory: PropTypes.bool,
    // Should it be displayed with a frame around it?
    hasFrame: PropTypes.bool,
    disabled: PropTypes.bool,
    noErrorMessage: PropTypes.bool,
    error: PropTypes.string,
    hasError: PropTypes.bool,
    weakBrowserMode: PropTypes.bool,
  };

  static contextTypes = {
    registerFormElement: PropTypes.func,
    validateField: PropTypes.func,
  };

  state = {};

  componentDidMount() {
    this.context.registerFormElement && this.context.registerFormElement(this.props.name, this);
  }

  get value() {
    return this._ref.value;
  }

  set value(value) {
    this._ref.value = value || '';
  }

  reset() {
    this.value = null;
  }

  validate() {
    const { validateField } = this.context;

    if (validateField) {
      this.setState({
        localError: validateField(this.props.name, this.value),
      });
    }
  }

  clearLocalError() {
    this.setState({
      localError: false,
    });
  }

  @autobind
  onChange(event) {
    const { onChange } = this.props;
    this.validate();
    const { options, selectedIndex } = event.target;
    const label = options[selectedIndex].label;
    if (onChange) onChange(this.value, label);
  }

  @autobind
  onBlur() {
    const { onBlur } = this.props;
    this.validate();
    if (onBlur) onBlur(this.value, this);
  }

  get hasChanged() {
    return this.value !== (this.props.value || '');
  }

  render() {
    const {
      name,
      label,
      placeholder,
      value,
      mandatory,
      // hasFrame,
      disabled,
      hasError,
      noErrorMessage,
      // weakBrowserMode,
      options,
    } = this.props;
    const errorMessage = this.state.localError || this.props.error;
    const error = hasError || errorMessage;

    return (
      <Select
        id={name}
        mandatory={mandatory}
        label={label}
        options={options}
        ref={(ref) => (this._ref = ref)}
        error={noErrorMessage ? undefined : error}
        disabled={disabled}
        defaultValue={value}
        placeholder={placeholder}
        onChange={this.onChange}
        onBlur={this.onBlur}
      />
    );
  }
}

export default FormSelect;
