// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../redesign/Button';

const FormButton = ({ children, disabled, dark, big, ...props }) => (
  <Button
    {...props}
    type="submit"
    disabled={disabled}
    size={big ? 'lg' : 'md'}
    variant={dark ? 'dark' : 'primary'}
    className="w-full"
  >
    {children}
  </Button>
);

FormButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  dark: PropTypes.bool,
  big: PropTypes.bool,
};

export default FormButton;
