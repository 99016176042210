// @flow
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import '../sass/main.scss';
import '../tailwind.css';
import { AppContainer, setConfig } from 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';

// enable sentry tracking
import './tracking/sentry';

setConfig({
  // https://stackoverflow.com/questions/54770535/react-hot-loader-react-dom-patch-is-not-detected
  showReactDomPatchNotification: false,
});

import { configure } from 'mobx';
configure({
  enforceActions: 'observed',
});

// import './Kameleoon'; // uncomment this line to re-active TUICUNIT-617
import './tracking';
import Router from './router';

const render = (Component) => {
  const element = document.getElementById('app');
  if (!element) return;
  ReactDOM.render(
    <AppContainer>
      <Component />
    </AppContainer>,
    element
  );
};

render(Router);

// Hot Module Replacement API
if (module && module.hot && typeof module.hot.accept === 'function') {
  module.hot.accept('./router', () => {
    render(require('./router').default);
  });
}

// window.divCount = 0;
// window.mdiv = function(top, left, width, height) {
//   let div, id;
//
//   id = 'measuring-div' + window.divCount;
//   div = document.createElement('div');
//   div.id = id;
//   div.style.backgroundColor = '#008000';
//   div.style.display = 'block';
//   div.style.height = (height || 60) + 'px';
//   div.style.position = 'absolute';
//   div.style.top = (top || 0) + 'px';
//   div.style.left = (left || 0) + 'px';
//   div.style.width = (width || 320) + 'px';
//   div.style.zIndex = 10000;
//   div.style.opacity = 0.5;
//
//   document.body.appendChild(div);
//   window.divCount += 1;
//
//   return 'mdiv(top, left, width, height)';
// };

// window.setUser = (ln, fn, nr, pin) => {
//   ln = encodeURIComponent(ln);
//   fn = encodeURIComponent(fn);
//   nr = parseInt(nr, 10);
//   const params = `?lastName=${ln}&firstName=${fn}&bookingNumber=${nr}&pin=${pin}`;
//   console.log(params);
//   if (confirm(params)) {
//     window.location.href = `/${params}`;
//   }
// };

// /**
// input format:
// `?lastName=ERDMANN&firstName=ADRIAN&bookingNumber=1547404
// ?lastName=KOOPMANN&firstName=LEVKE&bookingNumber=1548915
// ?lastName=Winistörfer&firstName=Lea&bookingNumber=1564275`
//
// use carefully -> locked login
//  * */
// window.openPageForUsers = (users, page) => {
//   users = users.split('\n');
//
//   const load = (i) => {
//     if (users[i]) {
//       window.open(`${page}${users[i]}`, `page${i}`);
//     }
//
//     if (i < users.length) {
//       window.setTimeout(() => {
//         load(i + 1);
//       }, 128);
//     }
//   };
//
//   load(0);
// };

// Does nothing but break the bfcache of firefox and safari
// On FF, if you use the login via paramater functionality, hitting back after relogin via params
// would serve the whole page from memory, so that you are essentially look at the page and book stuff
// for the previous logged in user. This should clear that memory cache.
window.addEventListener('unload', function () {});
