// TODO: add flow types

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import padStart from 'lodash/padStart';
import range from 'lodash/range';

import FormSelect from './Select';
import ErrorMessage from './ErrorMessage';

const getHourOptions = (start) => {
  return range(parseInt(start, 10), 24).map((i) => ({
    value: padStart(i, 2, '0'),
    label: padStart(i, 2, '0'),
  }));
};

const getMinuteOptions = (start) => {
  const MINUTE_OPTIONS = [
    { value: '00', label: '00' },
    { value: '15', label: '15' },
    { value: '30', label: '30' },
    { value: '45', label: '45' },
  ];

  if (start) {
    const min = parseInt(start, 10);
    if (min <= 8) {
      return MINUTE_OPTIONS;
    } else if (min <= 15) {
      return [MINUTE_OPTIONS[1], MINUTE_OPTIONS[2], MINUTE_OPTIONS[3]];
    } else if (min <= 30) {
      return [MINUTE_OPTIONS[2], MINUTE_OPTIONS[3]];
    } else {
      return [MINUTE_OPTIONS[3]];
    }
  }

  return MINUTE_OPTIONS;
};

export default class FormTime extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    mandatory: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    hasError: PropTypes.bool,
    startTime: PropTypes.string,
  };

  static contextTypes = {
    registerFormElement: PropTypes.func,
    validateField: PropTypes.func,
  };

  static childContextTypes = {
    registerFormElement: PropTypes.func,
  };

  state = {};

  componentDidMount() {
    this.context.registerFormElement && this.context.registerFormElement(this.props.name, this);
  }

  getChildContext() {
    return {
      // prohibt leakage of FormSelect values to parent Block
      registerFormElement: () => {},
    };
  }

  get value() {
    if (!this._minute.value && !this._hour.value) return '';
    if (!this._minute.value || !this._hour.value) return ':';
    return `${this._hour.value}:${this._minute.value}`;
  }

  validate() {
    const { validateField } = this.context;

    if (validateField) {
      this.setState({
        localError: validateField(this.props.name, this.value),
      });
    }
  }

  clearLocalError() {
    this.setState({
      localError: false,
    });
  }

  @autobind
  onChange() {
    const { onChange } = this.props;
    // Because we cannot determine if the user has finished entering all the date inputs
    // or if he gets distracted of other fields, we simple don't validate the date when
    // changing the input. Validation on form submit shoulds still happen though.
    this.setState({ localError: null });
    if (onChange) onChange(this.value);
  }

  @autobind
  onBlur() {
    const { onBlur } = this.props;
    if (onBlur) onBlur(this.value, this);
  }

  get hasChanged() {
    return this.value !== (this.props.value || '');
  }

  render() {
    const { name, label, value, mandatory, disabled, hasError, startTime } = this.props;
    const errorMessage = this.state.localError || this.props.error;
    const error = !!(hasError || errorMessage);

    const [initHours, initMinutes] = (value || '').split(':');

    let startHours = '00';
    let startMinutes = '00';
    if (startTime) {
      startHours = startTime.split(':')[0];
      startMinutes = startTime.split(':')[1];
    }

    return (
      <div
        className={classNames({
          'form-date': true,
          disabled,
          error,
        })}
      >
        {label ? (
          <label
            className={classNames('font-bold form-date__label', {
              error,
            })}
          >
            {label}
            {mandatory ? '*' : ''}
          </label>
        ) : null}
        <div className="l-spread">
          <div className="l-spread_left">
            <FormSelect
              noErrorMessage
              hasError={error}
              disabled={disabled}
              name={`${name}.hours`}
              value={initHours}
              placeholder="Stunde…"
              options={getHourOptions(startHours)}
              onChange={this.onChange}
              onBlur={this.onBlur}
              ref={(ref) => {
                this._hour = ref;
              }}
            />
            <span className="text-xs text-medium-emphasis">Stunde</span>
          </div>
          <div className="l-spread_left">
            <FormSelect
              noErrorMessage
              hasError={error}
              disabled={disabled}
              name={`${name}.minutes`}
              value={initMinutes}
              placeholder="Minute…"
              options={getMinuteOptions(this._hour && this._hour.value === startHours ? startMinutes : null)}
              onChange={this.onChange}
              onBlur={this.onBlur}
              ref={(ref) => {
                this._minute = ref;
              }}
            />
            <span className="text-xs text-medium-emphasis">Minute</span>
          </div>
        </div>
        <ErrorMessage error={errorMessage} />
      </div>
    );
  }
}
