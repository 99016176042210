// @flow

import React, { Component } from 'react';
import { locationShape } from 'react-router/lib/PropTypes';
import { observer } from 'mobx-react';

// TUICROLLOUTS-627 dismantling for 1.10.0.
//    Restore for 1.11.0 if 1.10.0 was released
// import PortalIntro from '../portal-intro/PortalIntroText';
// import PortalIntro from '../portal-intro/PortalIntroPin';
// TUICUNIT-2027
import PortalIntro from '../portal-intro/PortalIntroCheckin';

import Headline from '../Headline';
import LoginTeaser from '../LoginTeaser';
import LoginForm from '../LoginForm';
import ServiceTeamTeaser from '../ServiceTeamTeaser';
import CabinPresentsTeaser from '../CabinPresentsTeaser';
import AppStoreTeaser from '../AppStoreTeaser';
import track from '../../tracking';
import { isOptOut } from '../../utils/optOut';
import { getNewLoginUrl, shouldUseOldLogin } from '../../utils/useOldLogin';

@observer
export default class PageLogin extends Component<Props> {
  static propTypes = {
    location: locationShape,
  };

  componentDidMount() {
    // @todo: Shouldn't this component redirect to the  home page if the user is already logged in?
    // Fix for utag loading race condition
    if (!isOptOut()) {
      const int = setInterval(() => {
        if (window.utag) {
          track.pageView(window.location.pathname, ['Login']);
          clearInterval(int);
        }
      }, 50);
    }
  }

  render() {
    const { query } = this.props.location;

    if (!shouldUseOldLogin()) {
      window.location.href = getNewLoginUrl().toString();
      return null;
    } else {
      console.debug('Redirect is blocked. Original redirect:', getNewLoginUrl().toString());
    }

    return (
      <div>
        <LoginTeaser />
        <div className="or-divider">ODER</div>
        <div className="page page-login">
          <Headline title="Meine Reise" />
          <div className="flex gap-8 mb-8">
            <div>
              <PortalIntro />
            </div>
            <div className="max-w-[272px]">
              <LoginForm showInitialError={!!(query && query.loginFailed)} />
            </div>
          </div>

          <div className="l-row">
            <div className="l-col">
              <div className="l-mod-sub">
                <ServiceTeamTeaser />
              </div>
            </div>

            <div className="l-col">
              <div className="l-mod-sub">
                <CabinPresentsTeaser />
              </div>
            </div>

            <div className="l-col">
              <AppStoreTeaser />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
