// @flow
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import includes from 'lodash/includes';
import findIndex from 'lodash/findIndex';

import InfoList from '../../../components/InfoList';
import LoadingIndicator from '../../../components/LoadingIndicator';
import BlockList from '../../../components/lists/BlockList';

import type PartyMember from '../../../models/PartyMember';
import type PaymentInfo from '../../../models/PaymentInfo';
import { Button } from '../../redesign/Button';
import { Link } from 'react-router';

type Props = {
  travelParty: PartyMember[],
  paymentInfos: PaymentInfo[],
  paymentInfosLoaded: boolean,
};

@observer
export default class PaymentSummary extends Component<Props> {
  render() {
    const { paymentInfos, paymentInfosLoaded, travelParty } = this.props;

    return (
      <div>
        <h2>Zahlungsart für die Bordrechnung</h2>
        {paymentInfosLoaded ? (
          paymentInfos
            .filter((info) => info.isSelfPayer)
            .map((info) => (
              <div key={info.mpi}>
                <InfoList infos={info.displayInfos} />
                <p>
                  <strong>Teilnehmer, die über diese Rechnung bezahlen:</strong>
                </p>
                <BlockList inverted>
                  {travelParty
                    .filter((m) => includes(info.payedMpis, m.mpi))
                    .map((member) => (
                      <BlockList.Item key={member.mpi}>{member.displayName}</BlockList.Item>
                    ))}
                </BlockList>
                <div className="flex justify-end">
                  <Button
                    as={Link}
                    variant="secondary"
                    to={`/checkin/payment/${findIndex(travelParty, (m) => m.mpi === info.payerMpi)}`}
                  >
                    Ändern
                  </Button>
                </div>
              </div>
            ))
        ) : (
          <LoadingIndicator />
        )}
      </div>
    );
  }
}
