// @flow
import React, { Component } from 'react';
import { computed, observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import preload from '../../../components/pages/decorators/preload';
import requirements from '../../../components/pages/decorators/requirements';
import Headline from '../../../components/Headline';
import InfoBox from '../../../components/InfoBox';
import BreadcrumbLink from '../../../components/breadcrumbs/BreadcrumbLink';
import LoadingIndicator from '../../../components/LoadingIndicator';
import PaymentForm from '../../../components/Manifest/Payment/Form';
import PaymentSuccess from '../../../components/Manifest/Payment/Success';

import type PaymentStore from '../../../stores/PaymentStore';
import type MasterStore from '../../../stores/MasterStore';

import type PaymentStoreRequest from '../../../models/PaymentStoreRequest';
import type PartyMember from '../../../models/PartyMember';

import { MASTERDATA_STATUS_PAYMENT_ROUTING_GROUP_CHECKED_IN } from '../../../config/constants';

import { routerShape } from 'react-router/lib/PropTypes';
import pinStorage from '../../../utils/pinStorage';
import { pinRedirectUrl } from '../../../utils/pinRedirectUrl';
import { isIE } from '../../../utils/browser';
import type ManifestStore from '../../../stores/ManifestStore';
import type ManifestData from '../../../models/ManifestData';
import track from '../../../tracking';

type BreadcrumbProps = {
  masterStore: MasterStore,
  params: {
    memberIndex: number,
  },
};
type Props = {
  masterStore: MasterStore,
  paymentStore: PaymentStore,
  manifestStore: ManifestStore,
  manifestData: ManifestData,
  params: Object,
  route: { isCheckinSubRoute: boolean },
};

@inject('masterStore')
@observer
class PageManifestPaymentBreadcrumb extends Component<BreadcrumbProps> {
  render() {
    const { masterStore } = this.props;
    if (!masterStore.masterData) return;
    const member: ?PartyMember = masterStore.masterData.getPartyMemberByIndex(this.props.params.memberIndex);
    return member ? <BreadcrumbLink {...this.props} text={`Zahlungsmittel: ${member.displayName}`} /> : null;
  }
}

@inject('paymentStore', 'manifestStore', 'breadcrumbRouterStore')
@preload({
  masterStore: 'MasterStore',
  manifestData: 'UserManifest',
})
@requirements(['paymentAvailable'])
@observer
export default class PageManifestPayment extends Component<Props> {
  static breadcrumb = PageManifestPaymentBreadcrumb;

  static contextTypes = {
    router: routerShape,
  };

  @observable storeRequest: PaymentStoreRequest;

  componentDidMount() {
    if (!isIE()) {
      window.addEventListener('storage', this.storageChanged);
    }

    if (this.currentMember) {
      pinStorage.removeUrl(this.currentMember.mpi); // TUICUNIT-911
    }

    this.createStoreRequest();

    // Page View Tracking
    let titles = this.props.breadcrumbRouterStore.getWithoutFirst;
    if (titles) {
      titles.pop();
      track.pageView(window.location.pathname, [
        ...titles,
        'Zahlungsmittel',
        'person-' + this.props.params.memberIndex,
      ]);
    }
  }

  componentWillUnmount() {
    if (!isIE()) {
      window.removeEventListener('storage', this.storageChanged);
    }
  }

  @autobind
  storageChanged() {
    if (!isIE()) {
      this.createStoreRequest();
    }
  }

  createStoreRequest() {
    const { paymentStore, manifestData } = this.props;
    if (!this.currentMember || !manifestData) return;
    const formData = manifestData.data;
    const { contact } = formData;

    paymentStore.createStoreRequest(this.currentMember, contact).then(
      action((storeRequest) => {
        // TUICUNIT-911
        const error = pinStorage.getApiPinError();
        if (error) {
          this.context.router.replace(pinRedirectUrl(pinStorage.getUrl(error.mpi) || '/'));
          return;
        }

        this.storeRequest = storeRequest;
      })
    );
  }

  @autobind
  handleSave() {
    const { paymentStore } = this.props;
    if (this.storeRequest.showPayingForm) {
      this.storeRequest.validateData().then(
        () => {
          paymentStore.doRequest(this.storeRequest);
        },
        () => {}
      );
    } else {
      this.storeRequest.setDone();
    }
  }

  @computed
  get currentMember(): ?PartyMember {
    const { params, masterStore } = this.props;
    return masterStore.masterData.getPartyMemberByIndex(params.memberIndex);
  }

  renderInfo() {
    if (!this.currentMember) return null; // make flow happy

    if (
      !this.currentMember.paymentEnabled &&
      this.currentMember.paymentStatusReason === MASTERDATA_STATUS_PAYMENT_ROUTING_GROUP_CHECKED_IN
    ) {
      return (
        <InfoBox className="l-mod">
          <h2>Hinweis</h2>
          <p>
            Eine Änderung Ihrer Zahldaten ist nicht mehr möglich, da ein Gast aus Ihrer Zahlungsgruppe bereits
            eingecheckt ist. Um Ihre Zahldaten zu ändern wenden Sie sich bitte an Bord an die Rezeption.
          </p>
        </InfoBox>
      );
    }

    if (this.currentMember.paymentPreliminary) {
      return (
        <InfoBox className="l-mod">
          <h2>Hinweis</h2>
          <p>
            Eine Änderung Ihrer Zahldaten ist derzeit nicht möglich, da sich die Daten in Bearbeitung befinden. Bitte
            versuchen Sie es zu einem späteren Zeitpunkt noch einmal.
          </p>
        </InfoBox>
      );
    }

    if (!this.currentMember.paymentEnabled) {
      return (
        <InfoBox className="l-mod">
          <h2>Hinweis</h2>
          <p>
            Die Bearbeitung Ihrer Zahlungsdaten ist nicht mehr möglich. Bitte wenden Sie sich hierfür zu Beginn Ihrer
            Reise an Bord an die Rezeption.
          </p>
        </InfoBox>
      );
    }

    if (this.storeRequest.isCash) {
      return (
        <div className="l-row">
          <div className="l-col double">
            <p>
              Wir bitten Sie um Verständnis, dass aus Vorsoglichen Gründen zurzeit keine Barzahlung auf der Mein Schiff
              verfügbar ist. Eine neue Hinterlegung Ihrer Zahlungsdaten ist zur Begleichung Ihrer Bordabrechung
              erforderlich. Bitte beachten Sie auch, dass der verpflichtende Online Check-in 21 Tage vor Reisebeginn
              erst danach freigeschaltet werden kann.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="l-row">
        <div className="l-col double">
          <p>
            Hinterlegen Sie bereits jetzt die Zahlungsdaten für Ihre Bordabrechnung. Dies spart Ihnen Zeit beim Check-in
            auf dem Schiff und Sie können direkt entspannt in Ihren Wohlfühlurlaub starten.
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { paymentStore } = this.props;
    const isCheckinSubRoute = this.props.route.isCheckinSubRoute;
    const isInAppView = this.props.masterStore.isInAppView;
    const currentMember = this.currentMember;

    if (!this.storeRequest) return <LoadingIndicator />;

    if (!currentMember) return; // make flow happy

    const paymentCanNotBeChanged =
      currentMember.paymentStatusReason === MASTERDATA_STATUS_PAYMENT_ROUTING_GROUP_CHECKED_IN;

    if (this.storeRequest.isDone) {
      return <PaymentSuccess isCheckinSubRoute={isCheckinSubRoute} memberIndex={this.props.params.memberIndex} />;
    }

    return (
      <div>
        <Headline
          title={`${
            this.storeRequest.isCash
              ? 'Bitte wählen Sie eine neue Zahlungsart'
              : 'Zahlungsmittel für Bordabrechnung hinterlegen'
          }`}
        />
        <p>
          Hinterlegen Sie bereits jetzt die Zahlungsdaten für Ihre Bordabrechnung. Dies spart ihnen Zeit beim Check-In
          auf dem Schiff und Sie können direkt entspannt in Ihren Wohlfühlurlaub starten!
        </p>
        <h2>Zahlungsart</h2>
        <p>
          Durch das Hinterlegen Ihrer Zahldaten ist es Ihrerseits nicht mehr notwendig diese nochmals für die
          Begleichung Ihrer Bordabrechnung an Bord einlesen zu lassen und Ihr Wohlfühlurlaub kann direkt beginnen! Bitte
          beachten Sie, dass für einen vorzeitigen Online Check-In die Angabe einer Zahlungsart verpflichtend ist.
          <br />
          Ein Zahlungstransaktionsentgelt fällt für die Begleichung der Bordabrechnung nicht an.
        </p>
        {this.renderInfo()}
        <PaymentForm
          paymentCanNotBeChanged={paymentCanNotBeChanged}
          storeRequest={this.storeRequest}
          disabled={!currentMember.paymentEnabled}
          preliminary={currentMember.paymentPreliminary}
          paymentStore={paymentStore}
          onSave={this.handleSave}
          isInAppView={isInAppView}
          isCheckinSubRoute={!!isCheckinSubRoute}
        />
      </div>
    );
  }
}
