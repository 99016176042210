import React from 'react';
import { tv } from 'tailwind-variants';
import { Link } from 'react-router';
import tracking from '../../tracking';
var tileVariants = tv({
    slots: {
        root: 'grow h-[75px] md:h-[150px] flex md:flex-col bg-white px-4 justify-center items-center gap-2',
        icon: 'size-10',
        content: 'flex md:flex-col grow md:grow-0 justify-between items-center gap-1 flex-wrap',
        title: 'font-bold text-md',
        badge: 'rounded-full py-1 px-2 bg-marine-blue-10 text-xs',
    },
    variants: {
        clickable: {
            true: {
                root: 'cursor-pointer',
            },
        },
        disabled: {
            true: {
                root: "cursor-not-allowed",
            }
        }
    },
});
export var Tile = function (_a) {
    var Icon = _a.icon, title = _a.title, badge = _a.badge, onClick = _a.onClick, link = _a.link, linkIsExternal = _a.linkIsExternal, rawBadge = _a.rawBadge, disabled = _a.disabled;
    var Component = (onClick || link) && !disabled ? Link : 'div';
    var tileClasses = tileVariants({ clickable: !!onClick || !!link, disabled: disabled });
    var handleClick = function (event) {
        if (disabled)
            return;
        if (title) {
            tracking.click('content.tile.' + title);
        }
        else {
            tracking.click('content.tile');
        }
        onClick === null || onClick === void 0 ? void 0 : onClick(event);
    };
    return (React.createElement(Component, { to: link, target: linkIsExternal ? '_blank' : undefined, className: tileClasses.root(), onClick: handleClick, disabled: disabled },
        React.createElement(Icon, { className: tileClasses.icon() }),
        React.createElement("div", { className: tileClasses.content() },
            React.createElement("div", { className: tileClasses.title() }, title),
            badge && (React.createElement("div", { className: !rawBadge ? tileClasses.badge() : undefined }, badge)))));
};
