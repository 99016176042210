// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { Checkbox } from '../redesign/Checkbox';

class FormCheckbox extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func,
    mandatory: PropTypes.bool,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
  };

  static contextTypes = {
    registerFormElement: PropTypes.func,
    validateField: PropTypes.func,
  };

  state = {};

  componentDidMount() {
    this.context.registerFormElement && this.context.registerFormElement(this.props.name, this);
  }

  get value() {
    return this._ref.checked;
  }

  set value(value) {
    this._ref.checked = value;
  }

  validate() {
    const { validateField } = this.context;

    if (validateField) {
      const localError = validateField(this.props.name, this.value);
      this.setState({
        localError,
      });
    }
  }

  clearLocalError() {
    this.setState({
      localError: false,
    });
  }

  @autobind
  handleChange() {
    this.validate();
    if (this.props.onChange) this.props.onChange(this.value);
  }

  get hasChanged() {
    return this.value !== !!this.props.checked;
  }

  render() {
    const { name, label, className, mandatory, checked, disabled, hasError } = this.props;
    const { localError } = this.state;
    const error = hasError || localError;

    return (
      <Checkbox
        id={name}
        description={
          <>
            {label}
            {mandatory ? '*' : ''}
          </>
        }
        className={className}
        classNames={{ root: 'mb-2' }}
        defaultChecked={checked}
        disabled={disabled}
        ref={(ref) => (this._ref = ref)}
        onChange={this.handleChange}
        error={error}
      />
    );
  }
}

export default FormCheckbox;
