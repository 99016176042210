var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { forwardRef } from "react";
import { tv } from "tailwind-variants";
var linkVariant = tv({
    base: "underline",
});
export var Link = forwardRef(function (_a, ref) {
    var as = _a.as, children = _a.children, className = _a.className, props = __rest(_a, ["as", "children", "className"]);
    var Component = as !== null && as !== void 0 ? as : "a";
    var linkClass = linkVariant({ className: className });
    return (React.createElement(Component, __assign({}, props, { className: linkClass, ref: ref }), children));
});
