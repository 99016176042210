// @flow
import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import PaymentOptions from '../../../components/Manifest/Payment/PaymentOptions';
import PaymentSepaOption from '../../../components/Manifest/Payment/SepaOption';
import PaymentCreditCardOption from '../../../components/Manifest/Payment/CreditCardOption';
import PaymentCashOption from '../../../components/Manifest/Payment/CashOption';

import {
  PAYMENT_OPTION_SEPA,
  PAYMENT_OPTION_VISA,
  PAYMENT_OPTION_MASTER_CARD,
  PAYMENT_OPTION_AMERICAN_EXPRESS,
} from '../../../config/constants';

import type PaymentStoreRequest from '../../../models/PaymentStoreRequest';
type Props = {
  storeRequest: PaymentStoreRequest,
  isInAppView: boolean,
  disabled: boolean,
};

@observer
export default class ManifestPaymentSelector extends Component<Props> {
  @observable payForOthersEnabled = false;
  @observable payForMpis = [];

  @autobind
  handleOptionChange(option: string) {
    this.props.storeRequest.changeOption(option);
  }

  renderPaymentContent() {
    const { storeRequest, disabled, isInAppView } = this.props;

    if (storeRequest.isSepa) {
      return <PaymentSepaOption storeRequest={storeRequest} disabled={disabled} />;
    }
    if (storeRequest.isCash) {
      return <PaymentCashOption storeRequest={storeRequest} />;
    }
    if (storeRequest.isCreditCard) {
      return <PaymentCreditCardOption storeRequest={storeRequest} isInAppView={isInAppView} disabled={disabled} />;
    }
  }

  render() {
    const { storeRequest, disabled } = this.props;
    return (
      <div>
        <PaymentOptions
          name="paymentOptions"
          options={[
            {
              type: PAYMENT_OPTION_SEPA,
              disabled: false,
            },
            {
              type: PAYMENT_OPTION_VISA,
              disabled: false,
            },
            {
              type: PAYMENT_OPTION_MASTER_CARD,
              disabled: false,
            },
            {
              type: PAYMENT_OPTION_AMERICAN_EXPRESS,
              disabled: false,
            },
          ]}
          selected={storeRequest.selectedOptionOrDefault}
          onChange={this.handleOptionChange}
          disabled={disabled}
        />
        {storeRequest.error ? <p className="error-message">{storeRequest.error}</p> : null}

        {this.renderPaymentContent()}
      </div>
    );
  }
}
