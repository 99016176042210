// @flow
import storage from '../../utils/storage';
import config from '../../config';
import { isAndroid, isIOS } from '../../utils/OSDetection';
import { kebabCase } from '../../utils/kebabCase';
import { BLACKLISTED_PATHS } from './blacklist';
import { authStore } from '../../stores/AuthenticationStore';
import { PAGE_NAME_REPLACEMENT_MAP } from './replacements';
import { generateLegacyPageType } from './legacy';
import { tealiumString } from '../../utils/tracking';
import type { TrackingData } from '../../types/tracking';

/**
 * Tracking - Page View
 *
 * Utils to handle page tracking events.
 *
 * Page View Tracking happens in three steps:
 * 1. The router triggers a tracking event for all routes.
 * 2. A blacklist inside the page view tracking prevents some routes from being tracked automatically.
 *    These routes have to be tracked manually inside the respective components.
 * 3. A parameter replacement happens inside the page view tracking to change some values
 *    to match some external requirements.
 */
export function trackPageView(path: string, titles: Array, browserLang: string, category: ?string = null) {
  const params = getDefaultPageViewParameters();

  // User came directly from login without any parameters set - no tracking should be performed, a redirect will happen
  if (path === '/' && null === params) {
    return false;
  }

  return tagPageView(
    assembleTrackingData(path, stringifyTitle(typeof titles === 'string' ? titles : [...new Set(titles)]), category),
    browserLang,
    params
  );
}

export function isBlacklistedPath(path: string): boolean {
  return (
    BLACKLISTED_PATHS.filter((entry) => {
      const regex = new RegExp(entry);
      return regex.test(path);
    }).length > 0
  );
}

interface DefaultParameters {
  trip_code: string;
  trip_date_end: string;
  trip_date_range: string;
  trip_date_start: string;
  trip_name: string;
  trip_ship_name: string;
  user_id: string;
}

function getDefaultPageViewParameters(): ?DefaultParameters {
  const params = storage.get('tracking') || null;
  let result = null;
  if (params) {
    result = {
      trip_code: params.tripCode || '',
      trip_date_end: params.ddFull || '',
      trip_date_range: params.period || '',
      trip_date_start: params.edFull || '',
      trip_name: params.tripName || '',
      trip_ship_name: params.ship || '',
      user_id: params.userId || '',
    };
  }
  return result;
}

const PAGE_NAME_SEPARATOR = '|';

function performReplacements(title: string): string {
  for (const [key, value] of Object.entries(PAGE_NAME_REPLACEMENT_MAP)) {
    title = title.replace(key, value);
  }
  return title;
}

function stringifyTitle(title: Array): string {
  // An array of titles will be kebabyfied and joined
  if (Array.isArray(title)) {
    return title.map((entry) => kebabCase(entry)).join(PAGE_NAME_SEPARATOR);
  }

  // Variable has wrong type of data
  return '';
}

function assembleTrackingData(
  path: string,
  title: string,
  // TCGEN-1101: Not needed anymore but there for compatibility
  /* eslint-disable-next-line no-unused-vars */
  _category: ?string
): TrackingData {
  // Dissect URL
  const url = new URL(window.location.href);
  const hostname = url.hostname.replaceAll('.', '_');
  const queryParameter = url.search.replace('?', '') ?? null;
  const searchParams = new URLSearchParams(url.search);
  const isNativeAppView = (searchParams.get('app') && parseInt(searchParams.get('app')) === 1) ?? false;

  // page_name
  const pageNameFragments = [hostname, title];

  const pageName = performReplacements(pageNameFragments.join(PAGE_NAME_SEPARATOR));

  // Default values
  return {
    page_name: tealiumString(pageName),
    page_url_parameter: queryParameter,
    page_category: 'content',
    page_login_status: isUserLoggedIn(),
    page_login_variant: getLoginVariant(),

    page_type: generateLegacyPageType(path, title),

    page_platform: 'meinereisen',
    page_country: 'de',
    page_language: 'de',
    page_test_variant: 'original',
    page_native_app: isNativeAppView,
    page_native_app_os: isIOS ? 'ios' : isAndroid ? 'android' : '',
    page_b2b: false,
  };
}

function isUserLoggedIn() {
  return typeof authStore.user !== 'undefined' && !!authStore.user?.apiToken;
}

function getLoginVariant(): ?string {
  if (!authStore.user) {
    return null;
  }
  return authStore.user?.backLink ? 'konto' : 'meinereisen';
}

function tagPageView(trackingData: TrackingData, browserLang: string, params: ?DefaultParameters = {}) {
  let result = trackingData;

  if (trackingData.page_type === 'home_logged_in') {
    result = { ...params, ...result };
  }

  if (trackingData.page_type !== 'home_logged_out') {
    result = {
      iadvize_site_id: config.iadvizeId,
      user_language: browserLang,
      ...result,
    };
  }

  // @todo: Remove after implementation!
  //console.info('%c Page View Tracking Tag Data', 'background: #f10000; color: #ffff00', result.page_name, result.page_category, result);

  if (window.utag && result !== '') {
    window.utag.view(result);
  }

  return result;
}
